import React from 'react';
import {Link} from "react-router-dom";
import {sendGaEvent} from "../../lib/utils";
import {GA_EVENTS, MESSAGING_LIBRARIES_DOWNLOAD} from "../../lib/constants";


const PromptItem = (props) => {
    const {content, showAltLink, gaEvent, showSDKsLink} = props;
    return (
        <article>
            {content.title && <h4>{content.title}</h4>}
            {content.link.type === 'route' ?
                <Link to={ content.link.stateFrom ?
                    {
                        pathname: content.link.address,
                        state:
                        {
                            from: content.link.stateFrom
                        }
                    }:
                    content.link.address}
                  onClick={()=> {
                  sendGaEvent(gaEvent)}}>
                    {content.link.text}
                </Link>:
                <a href={!showAltLink ? content.link.address: content.link.alt}
                   rel="noopener noreferrer"
                   target='_blank'
                   onClick={()=> {
                       sendGaEvent(gaEvent)}}>
                    {content.link.text}
                </a>
            }
            {content.note ?
                <p>
                    {content.note}
                </p>:
            null}
            {showSDKsLink ?
                <Link to={'/' + MESSAGING_LIBRARIES_DOWNLOAD}
                      className='fin-messages__msg-types--samples__sdks'
                      onClick={()=> {
                          sendGaEvent({category: 'page_internal_link', action: GA_EVENTS.finMsgs.sdksUpper}
                          )}}>
                    Download demo SDKs
                </Link>
            :null}
        </article>
    );
}

export default PromptItem;
