import React, {useEffect} from 'react';
import DOMPurify from "dompurify";
import '../../../assets/css/Crm.css';
import {Helmet} from "react-helmet";
import {SEO_TAGS} from "../../lib/constants";

const DownloadLibrariesPage =()=> {
    const pureHTML = `<div id='crmWebToEntityForm' class='zcwf_lblLeft crmWebToEntityForm' style='background-color: white;color: black;max-width: 400px;'>
<form name='WebToLeads4703922000001130010' method='POST' accept-charset='UTF-8' id='crmFormDownloadLib'>
<input type='text' style='display:none;' name='xnQsjsdp' value='c969dc5924744b784d66f99feae6f236874fc24d2fceca3778d9634461a64e43'></input>
<input type='hidden' name='zc_gad' id='zc_gad' value=''></input>
<input type='text' style='display:none;' name='xmIwtLD' value='051f745cb5ce03bafd4e6e65898864bc562b1fd73f687673a2affbd40249858fb9b3a13842bfb876dbb355b5bfc12f42'></input>
<input type='text' style='display:none;' name='actionType' value='TGVhZHM='></input>
<input type='text' style='display:none;' name='returnURL' value='${process.env.PUBLIC_URL}/thank-you' > </input>
<div class='zcwf_title' style='max-width: 600px;color: black;'>FINaplo Financial Messages Libraries - Demo Download</div>
<div class='zcwf_row'><div class='zcwf_col_lab' style='font-size:12px;'><label for='Last_Name'>Name<span style='color:red;'>*</span></label></div><div class='zcwf_col_fld'><input type='text' id='Last_Name' name='Last Name' maxlength='80'></input><div class='zcwf_col_help'></div></div></div>
<div class='zcwf_row'><div class='zcwf_col_lab' style='font-size:12px;'><label for='Email'>Company Email<span style='color:red;'>*</span></label></div><div class='zcwf_col_fld'><input type='text' ftype='email' id='Email' name='Email' maxlength='100'></input><div class='zcwf_col_help'></div></div></div>
<div class='zcwf_row'><div class='zcwf_col_lab' style='font-size:12px;'><label for='LEADCF2'>Demo Library of interest<span style='color:red;'>*</span></label>
</div>
<div class='zcwf_col_fld'>
<select class='zcwf_col_fld_slt' role='combobox' aria-expanded='false' aria-haspopup='listbox' id='LEADCF2' aria-required='true' aria-label='LEADCF2' name='LEADCF2'>
<option value='-None-'>-None-</option>
<option value='swiftMt'>SWIFT - &#x28;MT 101&#x29;</option>
<option value='iso20022'>ISO20022 - &#x28;PACS.002&#x29;</option>
<option value='sepa'>SEPA - &#x28;PACS.002&#x29</option>
<option value='cbprPlus'>CBPR&#x2b; - &#x28;PACS.009 CORE&#x29;</option>
<option value='cbprPlusTranslator'>SWIFT MT &lt;&gt; CBPR&#x2b; Translator - &#x28;MT202&lt;&gt;PACS.009 CORE&#x29;</option>
<option value='targetTwo'>TARGET2 - &#x28;PACS.009&#x29;</option>
<option value='targetTwoTranslator'>SWIFT MT &lt;&gt; TARGET2 Translator - &#x28;MT202&lt;&gt;PACS.009&#x29;</option>
<option value='MEPS&#x2b;'>MEPS&#x2b;</option>
<option value='SIC&#x2f;euroSIC'>SIC&#x2f;euroSIC</option>
<option value='FedNow'>FedNow</option>
<option value='BAHTNET'>BAHTNET</option>
</select><div class='zcwf_col_help'></div></div></div>
<div class='zcwf_row'><div class='zcwf_col_lab'></div>
<div class='zcwf_col_fld'>
<div class='g-recaptcha' data-sitekey='6LfG5OAZAAAAAFDqc2TVW7tU_Hp3KzkBgifOXD3w' data-theme='light' data-callback='rccallback4703922000001130010' id='recap4703922000001130010'></div>
<div  id='recapErr4703922000001130010' style='font-size:12px;color:red;visibility:hidden;margin-left:-130px;max-width:341px;margin-top:5px;'>Captcha validation failed. If you are not a robot then please try again.</div></div></div>
<div class='zcwf_row'>
<div class='zcwf_privacy'>
<div class='dIB vat' align='left'>
<div class='displayPurpose crm-small-font-size'>
<label class='newCustomchkbox-md dIB w100_per'>
<input autocomplete='off' id='privacyTool4703922000001130010' type='checkbox' aria-checked='false' name='' aria-errormessage='privacyErr4703922000001130010' aria-label='privacyTool'>
</label>
</div></div>
<div class='dIB zcwf_privacy_txt' style='font-size: 12px;color: black;'>I agree to the <a href='https://www.paymentcomponents.com/privacy-statement/' title='' target='_blank' data_issistrackenabled='false' data_siqtrackparam='null'>Privacy Policy</a>&nbsp;and&nbsp;<a href='https://www.paymentcomponents.com/terms-of-service/' title='https://www.paymentcomponents.com/terms-of-service/' target='_blank'>Terms of Service.</a></div>
<div  id='privacyErr4703922000001130010' style='font-size:12px;color:red;padding-left: 5px;visibility:hidden;'>Please accept this</div></div></div>
<div class='zcwf_row custom_newsletter'>
<div class='zcwf_privacy'>
<div class='dIB vat' align='left'>
<input type='checkbox' aria-checked='false' class='zcwf_ckbox' id='LEADCF102' aria-required='false' aria-label='LEADCF102' name='LEADCF102' aria-valuemax='3' maxlength='3'>                                                         </input>
<div class='zcwf_col_help'></div>
</div>
<div class='dIB zcwf_privacy_txt'>
<label for='LEADCF102'>I agree to receive industry news and updates</label>
</div>
</div>
</div>
<div class='zcwf_row custom_form-btns'>
<div class='zcwf_col_fld'>
<input type ='reset' class ='zcwf_button' role ='button' name ='reset' value ='Reset' aria-label ='Reset' title ='Reset'></input>
<input type='submit' id='formsubmit' class='formsubmit zcwf_button' value='Submit' title='Submit'></input>
</div></div>
</form>
</div>`;

    const safeHTML = DOMPurify.sanitize(pureHTML,  { ADD_ATTR: ["ftype"] });

    useEffect(()=> {
        window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (
        <section className='crm'>
            <Helmet>
                <meta name='viewport' content='width=device-width, initial-scale=1.0'/>
                <meta httpEquiv='content-type' content='text/html;charset=UTF-8'/>
                <script src='https://www.google.com/recaptcha/api.js' async defer/>
                <script src={process.env.PUBLIC_URL + '/crm/DownloadLibrariesScript.js'} type="text/javascript" />
                <title>{SEO_TAGS.crm.download.title}</title>
                <meta name="description" content={SEO_TAGS.crm.download.description}/>
                <link rel="canonical" href={process.env.PUBLIC_URL + '/download-swift-mt'}/>
                <meta property="og:title"  content={SEO_TAGS.crm.download.title}/>
                <meta property="og:description" content={SEO_TAGS.crm.download.description}/>
                <meta property="og:url" content={process.env.PUBLIC_URL + '/download-swift-mt'}/>
                <meta name="twitter:title" content={SEO_TAGS.crm.download.title}/>
                <meta name="twitter:description" content={SEO_TAGS.crm.download.description}/>
            </Helmet>
            <article dangerouslySetInnerHTML={{ __html: safeHTML }} />
        </section>
    );
}

export default DownloadLibrariesPage;
