import React, {useEffect, useState} from 'react';
import {Link, withRouter} from "react-router-dom";
import {
    APLONHUB_TITLE,
    FAQ_FIN_MESSAGES,
    FIN_MESSAGES_CONTENT,
    GA_EVENTS, PROMPT_ITEMS, ROUTE_PATHS
} from "../../../lib/constants";
import QuestionMailTo from "../../uiElements/QuestionMailTo";
import MessagesSpecifications from "./MessagesSpecifications";
import PromptItem from "../../uiElements/PromptItem";
import {Faq} from "../Faq";
import {useSelector} from "react-redux";
import {checkEmptyStr, scrollToAnchor} from "../../../lib/utils";
import AplonHub from "../../uiElements/AplonHub";


const FinancialMessagesPageContentComponent = (props) =>  {
    const {location} = props;
    const [userLoggedIn, setUserLoggedIn] = useState(false);
    const  user = useSelector(state => state.userReducer);


    useEffect(()=> {
        if (location.hash && !checkEmptyStr(location.hash)) {
            setTimeout(() => {
                scrollToAnchor(location.hash);
            }, 300);
        } else {
            window.scrollTo(0, 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[location, location.hash]);

    useEffect(() =>{
        if (!user.profile.fetching) {
            setUserLoggedIn(user.userLoggedIn);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[user.userLoggedIn]);

    return (
        <div className="fin-messages">
            <div className="fin-messages__content">
                <section className='fin-messages__block fin-messages__key-benefits'>
                    <div className='fin-messages__key-benefits--container'>
                        {FIN_MESSAGES_CONTENT.features.benefits.map((paragraph, idx)=> {
                            return <article key={'fin-messages-features-benefits-' + idx} >
                                        <h3>{paragraph.title}</h3>
                                        <p>
                                            {paragraph.text}
                                        </p>
                                    </article>
                        })}
                    </div>
                </section>
                <section className='prompt__block fin-messages__block fin-messages__libraries'>
                 <article>
                     <h4>
                         Our FINaplo Financial Messages Libraries
                     </h4>
                     <p>
                         They come both in java SDK and with an API wrapper. And there is a different library for each message standard. You can purchase the whole message library or just the messages you need.
                     </p>
                 </article>
                </section>
                <section className='prompt__block fin-messages__block fin-messages__quote fin-messages__quote--a'>
                    <PromptItem content={PROMPT_ITEMS['financialMessagesA']}
                                gaEvent={{category: 'page_internal_link', action: GA_EVENTS.finMsgs.cta1}}
                                showSDKsLink={true}
                    />
                </section>
                <section className='fin-messages__msg-types'>
                    <MessagesSpecifications />
                </section>
                <section className='fin-messages__block fin-messages__features'>
                    <div className='fin-messages__block fin-messages__features--container'>
                        <article className='fin-messages__licensing'>
                            <h6>LICENSING</h6>
                            <p>
                                {FIN_MESSAGES_CONTENT.features.licensing}
                            </p>
                        </article>
                        <Link to={ '/' + ROUTE_PATHS['pricing']} className='fin-messages__offer'>
                            <h6 className='fin-messages__offer--hdr-img offer__hdr-img'>
                                <i className='bg-sprites bg-offer'></i>
                                <span>OFFER</span>
                            </h6>
                            <p>
                                {FIN_MESSAGES_CONTENT.features.offer}
                            </p>
                        </Link>
                    </div>
                </section>
                <section className='prompt__block fin-messages__block fin-messages__quote fin-messages__quote--b'>
                    <PromptItem content={PROMPT_ITEMS['financialMessagesB']}
                                gaEvent={{category: 'page_internal_link', action: GA_EVENTS.finMsgs.cta2}}
                    />
                </section>
                <section className='fin-messages__block fin-messages__faq'>
                    <Faq faqContent={FAQ_FIN_MESSAGES(userLoggedIn)}
                         componentKey='fin-messages-faq-content'
                         showTitle={true}
                    />
                </section>
                <section className='question__container fin-messages__question'>
                    <QuestionMailTo subject='About fin-messages'/>
                </section>
                <AplonHub title={APLONHUB_TITLE.finMessages} gaEvt={'finMessages'} />
            </div>
        </div>
    );
}

export const FinMessagesPageContent = withRouter(FinancialMessagesPageContentComponent);
